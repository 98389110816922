<template>
  <NConfigProvider
    :locale="deDE"
    :date-locale="dateDeDE"
    :theme-overrides="themeOverrides"
  >
    <NNotificationProvider>
      <NDialogProvider>
        <NSpin :show="isLoading">
          <NavBar />
          <div class="container mx-auto p-2">
            <RouterView v-if="!isLoading" />
            <div v-else class="h-screen"></div>
          </div>

          <template #description>
            <p>Lade Daten vom Server...</p>
          </template>
        </NSpin>
        <NGlobalStyle />
      </NDialogProvider>
    </NNotificationProvider>
  </NConfigProvider>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import type { GlobalThemeOverrides } from 'naive-ui';
import {
  dateDeDE,
  deDE,
  NConfigProvider,
  NGlobalStyle,
  NNotificationProvider,
  NDialogProvider,
  NSpin,
} from 'naive-ui';

import NavBar from './components/NavBar.vue';
import { useAuthStore } from '@/stores/auth';
import { sendErrorToSentry } from './sentry';

const themeOverrides: GlobalThemeOverrides = {
  common: {
    // set font-family to inherit to use styles set by tailwind
    fontFamily: 'inherit',
    fontWeight: '400',
  },
};

const router = useRouter();
const isLoading = ref(true);

onMounted(async () => {
  const authStore = useAuthStore();
  if (authStore.session === null) {
    try {
      await authStore.getActiveSession();
      if (authStore.session === null) {
        router.push({ name: 'login' });
      }
    } catch (error) {
      console.log(error);
      router.push({ name: 'login' });
      sendErrorToSentry(error, 'auth fehlgeschlagen');
    }
  }
  // TODO fix fake loading state
  setTimeout(() => {
    isLoading.value = false;
  }, 2000);
});
</script>
