import { createApp } from 'vue';
import { createPinia } from 'pinia';
// import virtual sentry config module from vite plugin
// this imports the dist and release settings used when publishing
import 'virtual:vite-plugin-sentry/sentry-config';

import router from '@/routes';
import App from './App.vue';

// import styles
import '@/assets/styles/index.css';
import { initSentry, sendErrorToSentry } from './sentry';

const app = createApp(App);

initSentry(app, router);

app.config.errorHandler = (err, instance, info) =>
  sendErrorToSentry(err, info, instance);

app.use(router).use(createPinia()).mount('#app');
